@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rethink+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

:root {
  --bg-color: #17181a;
  --bg-table: #1e1f21;
  --bg-s-color: #1e1f21;
  --text: white;
  --btn-bg: #ff535d;
  --nav-btn: rgb(200, 200, 200);
  --nav-bg: rgb(22, 22, 22);
  --card-bg: rgb(40, 40, 40);
  --input-bg: rgb(94, 94, 94);
  --bg-alert: rgb(34, 34, 34);
  --bar-prog: rgb(91, 91, 91);
}
[data-theme="dark"] {
  --bg-color: #17181a;
  --bg-table: #1e1f21;
  --bg-s-color: #272727;
  --text: white;
  --text-s: rgba(255, 255, 255, 0.5);
  --btn-bg: #ff535d;
  --nav-btn: rgb(200, 200, 200);
  --nav-bg: rgb(22, 22, 22);
  --card-bg: rgb(40, 40, 40);
  --input-bg: rgb(94, 94, 94);
  --bg-alert: rgb(34, 34, 34);
  --bar-prog: rgb(78, 78, 78);
}

[data-theme="light"] {
  --bg-color: rgb(239, 239, 239);
  --bg-table: rgb(210, 210, 210);
  --bg-s-color: #e1e1e1;
  --text: rgb(30, 30, 30);
  --text-s: rgba(0, 0, 0, 0.5);
  --btn-bg: #ff535d;
  --nav-btn: rgb(82, 82, 82);
  --nav-bg: rgb(190, 190, 190);
  --card-bg: rgb(204, 204, 204);
  --input-bg: rgb(246, 246, 246);
  --bg-alert: rgb(205, 205, 205);
  --bar-prog: rgb(184, 184, 184);
}

/* Seluruh elemen menggunakan variabel CSS */
body {
  background-color: var(--bg-color) !important;
  color: var(--text) !important;
}

.btn-ddn {
  background-color: var(--btn-bg) !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 8px 15px !important;
  color: white !important;
}

a {
  text-decoration: none !important;
  color: var(--text) !important;
}

.btn-login2 {
  display: none;
}

@media screen and (max-width: 991px) {
  .btn-login {
    display: none;
  }
  .btn-login2 {
    display: flex;
    max-width: 150px;
    max-height: 35px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

.pointer {
  cursor: pointer;
}
.accordion {
  width: 100% !important;
  position: relative;
  overflow: hidden;
}

.card-admin {
  background-color: var(--bg-s-color) !important;
  padding: 10px;
  border-radius: 10px;
  caret-color: transparent;
}

.card-admin2 {
  background-color: var(--bg-s-color);
  padding: 10px;
}

.btn-generateqr {
  background-color: var(--bg-s-color) !important;
  color: var(--text);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 20px;
}

.input-admin {
  height: 40px;
  min-width: 150px;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 5px 10px;
  background-color: var(--input-bg) !important;
  color: var(--text) !important;
}

.input-admin::placeholder {
  color: var(--text) !important;
  opacity: 60%;
}

.rdt_TableHeadRow {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: var(--text) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  font-weight: 600;
}

.rdt_TableCol {
  min-width: 150px !important;
  color: var(--text) !important;
}
.rdt_TableCell {
  min-width: 150px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: var(--text) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.rdt_Table {
  background-color: transparent !important;
  color: var(--text) !important;
}

.rdt_Table div {
  background-color: var(--bg-table) !important;
  color: var(--text);
}

.rdt_TableRow {
  background-color: transparent !important;
  color: var(--text) !important;
}

.rdt_Pagination button {
  fill: var(--text) !important;
}
.rdt_Pagination button:disabled {
  fill: var(--text-s) !important;
}

.rdt_Pagination {
  background-color: transparent !important;
  color: var(--text) !important;
  min-height: unset !important;
  margin-top: 15px;
  border: none !important;
}

.rdt_Pagination button {
  background-color: rgba(147, 147, 147, 0.515) !important;
  margin: 0px 5px;
}

/* WebKit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 6px; /* Lebar scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent; /* Warna latar belakang track scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #88888899; /* Warna thumb scrollbar */
  border-radius: 6px; /* Sudut border thumb */
}

/* Firefox */
::-moz-scrollbar {
  width: 6px; /* Lebar scrollbar */
  height: 6px;
}

::-moz-scrollbar-track {
  background: transparent; /* Warna latar belakang track scrollbar */
}

::-moz-scrollbar-thumb {
  background: #88888899; /* Warna thumb scrollbar */
  border-radius: 6px; /* Sudut border thumb */
}

.accordion {
  background-color: var(--bg-s-color);
  border-radius: 10px;
}

.accordion-item {
  border: none !important;
  background-color: transparent !important;
  color: var(--text) !important;
}

.accordion button {
  background-color: var(--bg-s-color) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  color: var(--text) !important;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.accordion button::after {
  background-color: rgba(188, 188, 188, 0.5) !important;
  border-radius: 5px;
}

.btn-download-qr {
  color: var(--text) !important;
  height: 32px;
  padding: 0 10px !important;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ReactModal__Overlay {
  background-color: rgba(80, 80, 80, 0.7) !important;
  backdrop-filter: blur(5px);
  z-index: 12;
  padding: 20px;
}

.ReactModal__Content {
  border: none !important;
  margin: auto;
}

#qr-canvas-visible {
  max-height: 400px !important;
}

.cardAllowLocation {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.cardAllowLocation div {
  position: relative;
  background-color: var(--bg-alert);
  padding: 20px;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-toggle {
  font-size: 14px !important;
}
