a.nav-link {
  padding: 14px 0px;
}

button.navbar-toggler {
  background-color: var(--nav-btn) !important;
  margin-bottom: 0 !important;
  width: 48px;
  height: 35px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.navbar-toggler-icon {
  width: 25px !important;
}

nav.navbar.navbar-expand-lg {
  padding: 0 !important;
}
.sidebar-ddn {
  background-color: var(--bg-s-color) !important;
  width: 250px;
  position: fixed;
  height: 100%;
  z-index: 10;
  overflow: scroll;
}

.sidebar-body {
  height: 100%;
}
.content-body {
  width: calc(100% - 250px) !important;
  margin-left: 250px !important;
}

@media screen and (max-width: 992px) {
  .content-body {
    margin-left: 200px !important;
    width: calc(100% - 200px) !important;
  }
  .sidebar-ddn {
    width: 200px;
    z-index: 10;
  }

  .tickert {
    font-size: 14px !important;
  }
  .ticker {
    font-size: 11px !important;
  }
}

.sidebar-ddn-active {
  background-color: var(--bg-s-color) !important;
  width: 50px !important;
  position: fixed;
  height: 100%;
  z-index: 10;
}
.content-body-active {
  width: calc(100% - 50px) !important;
  margin-left: 50px !important;
}

.tickert {
  font-size: 18px;
  font-weight: 600;
}
.ticker {
  font-size: 12px;
  opacity: 50%;
}

@media screen and (max-width: 992px) {
  .content-body-active {
    margin-left: 50px !important;
    width: calc(100% - 50px) !important;
  }
  .sidebar-ddn-active {
    z-index: 10;
  }
}

@media screen and (max-width: 768px) {
  .blocker {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #282828b7;
    backdrop-filter: blur(12px);
    top: 0;
    z-index: 5;
    left: 0;
    display: block !important;
  }
}

.navlink-active {
  display: none !important;
}

.blocker {
  display: none;
}
.blocker-active {
  display: none;
}

.theme-switch {
  --toggle-size: 20px;
  /* the size is adjusted using font-size,
       this is not transform scale,
       so you can choose any size */
  --container-width: 5.625em;
  --container-height: 2.5em;
  --container-radius: 6.25em;
  /* radius 0 - minecraft mode :) */
  --container-light-bg: #3d7eae;
  --container-night-bg: #1d1f2c;
  --circle-container-diameter: 3.375em;
  --sun-moon-diameter: 2.125em;
  --sun-bg: #ecca2f;
  --moon-bg: #c4c9d1;
  --spot-color: #959db1;
  --circle-container-offset: calc(
    (var(--circle-container-diameter) - var(--container-height)) / 2 * -1
  );
  --stars-color: #fff;
  --clouds-color: #f3fdff;
  --back-clouds-color: #aacadf;
  --transition: 0.5s cubic-bezier(0, -0.02, 0.4, 1.25);
  --circle-transition: 0.3s cubic-bezier(0, -0.02, 0.35, 1.17);
}

.theme-switch,
.theme-switch *,
.theme-switch *::before,
.theme-switch *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: var(--toggle-size);
}

.theme-switch__container {
  width: var(--container-width);
  height: var(--container-height);
  background-color: var(--container-light-bg);
  border-radius: var(--container-radius);
  overflow: hidden;
  cursor: pointer;
  -webkit-box-shadow: 0em -0.062em 0.062em rgba(0, 0, 0, 0.25),
    0em 0.062em 0.125em rgba(255, 255, 255, 0.94);
  box-shadow: 0em -0.062em 0.062em rgba(0, 0, 0, 0.25),
    0em 0.062em 0.125em rgba(255, 255, 255, 0.94);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.theme-switch__container::before {
  content: "";
  position: absolute;
  z-index: 1;
  inset: 0;
  -webkit-box-shadow: 0em 0.05em 0.187em rgba(0, 0, 0, 0.25) inset,
    0em 0.05em 0.187em rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0em 0.05em 0.187em rgba(0, 0, 0, 0.25) inset,
    0em 0.05em 0.187em rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--container-radius);
}

.theme-switch__checkbox {
  display: none;
}

.theme-switch__circle-container {
  width: var(--circle-container-diameter);
  height: var(--circle-container-diameter);
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: var(--circle-container-offset);
  top: var(--circle-container-offset);
  border-radius: var(--container-radius);
  -webkit-box-shadow: inset 0 0 0 3.375em rgba(255, 255, 255, 0.1),
    inset 0 0 0 3.375em rgba(255, 255, 255, 0.1),
    0 0 0 0.625em rgba(255, 255, 255, 0.1),
    0 0 0 1.25em rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 0 3.375em rgba(255, 255, 255, 0.1),
    inset 0 0 0 3.375em rgba(255, 255, 255, 0.1),
    0 0 0 0.625em rgba(255, 255, 255, 0.1),
    0 0 0 1.25em rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--circle-transition);
  -o-transition: var(--circle-transition);
  transition: var(--circle-transition);
  pointer-events: none;
}

.theme-switch__sun-moon-container {
  pointer-events: auto;
  position: relative;
  z-index: 2;
  width: var(--sun-moon-diameter);
  height: var(--sun-moon-diameter);
  margin: auto;
  border-radius: var(--container-radius);
  background-color: var(--sun-bg);
  -webkit-box-shadow: 0.062em 0.062em 0.062em 0em rgba(254, 255, 239, 0.61)
      inset,
    0em -0.062em 0.062em 0em #a1872a inset;
  box-shadow: 0.062em 0.062em 0.062em 0em rgba(254, 255, 239, 0.61) inset,
    0em -0.062em 0.062em 0em #a1872a inset;
  -webkit-filter: drop-shadow(0.062em 0.125em 0.125em rgba(0, 0, 0, 0.25))
    drop-shadow(0em 0.062em 0.125em rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0.062em 0.125em 0.125em rgba(0, 0, 0, 0.25))
    drop-shadow(0em 0.062em 0.125em rgba(0, 0, 0, 0.25));
  overflow: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.theme-switch__moon {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  background-color: var(--moon-bg);
  border-radius: inherit;
  -webkit-box-shadow: 0.062em 0.062em 0.062em 0em rgba(254, 255, 239, 0.61)
      inset,
    0em -0.062em 0.062em 0em #969696 inset;
  box-shadow: 0.062em 0.062em 0.062em 0em rgba(254, 255, 239, 0.61) inset,
    0em -0.062em 0.062em 0em #969696 inset;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.theme-switch__spot {
  position: absolute;
  top: 0.75em;
  left: 0.312em;
  width: 0.75em;
  height: 0.75em;
  border-radius: var(--container-radius);
  background-color: var(--spot-color);
  -webkit-box-shadow: 0em 0.0312em 0.062em rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0em 0.0312em 0.062em rgba(0, 0, 0, 0.25) inset;
}

.theme-switch__spot:nth-of-type(2) {
  width: 0.375em;
  height: 0.375em;
  top: 0.937em;
  left: 1.375em;
}

.theme-switch__spot:nth-last-of-type(3) {
  width: 0.25em;
  height: 0.25em;
  top: 0.312em;
  left: 0.812em;
}

.theme-switch__clouds {
  width: 1.25em;
  height: 1.25em;
  background-color: var(--clouds-color);
  border-radius: var(--container-radius);
  position: absolute;
  bottom: -0.625em;
  left: 0.312em;
  -webkit-box-shadow: 0.937em 0.312em var(--clouds-color),
    -0.312em -0.312em var(--back-clouds-color),
    1.437em 0.375em var(--clouds-color), 0.5em -0.125em var(--back-clouds-color),
    2.187em 0 var(--clouds-color), 1.25em -0.062em var(--back-clouds-color),
    2.937em 0.312em var(--clouds-color), 2em -0.312em var(--back-clouds-color),
    3.625em -0.062em var(--clouds-color), 2.625em 0em var(--back-clouds-color),
    4.5em -0.312em var(--clouds-color),
    3.375em -0.437em var(--back-clouds-color),
    4.625em -1.75em 0 0.437em var(--clouds-color),
    4em -0.625em var(--back-clouds-color),
    4.125em -2.125em 0 0.437em var(--back-clouds-color);
  box-shadow: 0.937em 0.312em var(--clouds-color),
    -0.312em -0.312em var(--back-clouds-color),
    1.437em 0.375em var(--clouds-color), 0.5em -0.125em var(--back-clouds-color),
    2.187em 0 var(--clouds-color), 1.25em -0.062em var(--back-clouds-color),
    2.937em 0.312em var(--clouds-color), 2em -0.312em var(--back-clouds-color),
    3.625em -0.062em var(--clouds-color), 2.625em 0em var(--back-clouds-color),
    4.5em -0.312em var(--clouds-color),
    3.375em -0.437em var(--back-clouds-color),
    4.625em -1.75em 0 0.437em var(--clouds-color),
    4em -0.625em var(--back-clouds-color),
    4.125em -2.125em 0 0.437em var(--back-clouds-color);
  -webkit-transition: 0.5s cubic-bezier(0, -0.02, 0.4, 1.25);
  -o-transition: 0.5s cubic-bezier(0, -0.02, 0.4, 1.25);
  transition: 0.5s cubic-bezier(0, -0.02, 0.4, 1.25);
}

.theme-switch__stars-container {
  position: absolute;
  color: var(--stars-color);
  top: -100%;
  left: 0.312em;
  width: 2.75em;
  height: auto;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.theme-switch__checkbox:checked + .theme-switch__container {
  background-color: var(--container-night-bg);
}

.theme-switch__checkbox:checked
  + .theme-switch__container
  .theme-switch__circle-container {
  left: calc(
    100% - var(--circle-container-offset) - var(--circle-container-diameter)
  );
}

.theme-switch__checkbox:checked
  + .theme-switch__container
  .theme-switch__circle-container:hover {
  left: calc(
    100% - var(--circle-container-offset) - var(--circle-container-diameter) -
      0.187em
  );
}

.theme-switch__circle-container:hover {
  left: calc(var(--circle-container-offset) + 0.187em);
}

.theme-switch__checkbox:checked + .theme-switch__container .theme-switch__moon {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.theme-switch__checkbox:checked
  + .theme-switch__container
  .theme-switch__clouds {
  bottom: -4.062em;
}

.theme-switch__checkbox:checked
  + .theme-switch__container
  .theme-switch__stars-container {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
