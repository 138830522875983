.card-login {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 500px;
  padding: 30px;
  border-radius: 10px;
  min-width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
}

.input-login {
  width: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 8px 16px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  caret-color: black;
}

.input-logis {
  width: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 8px 16px;
  font-size: 14px;
  caret-color: black;
  background-color: transparent;
}

.text-login {
  color: black;
}

.btn-login {
  background-color: #ff535d !important;
  border: none !important;
  padding: 5px 20px !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 10px #ff535b84;
  width: 110px;
}

.btn-login-outline {
  background-color: transparent !important;
  border-color: #ff535d !important;
  padding: 5px 20px !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 10px #ff535b84;
  color: #ff535d !important;
  font-weight: 600 !important;
  width: 110px;
}

.bg {
  position: absolute;
  top: 0;
  right: -50px;
}

.bg-coffee-1 {
  position: absolute;
  width: 100px;
  top: 10%;
  left: 20%;
}
.bg-coffee-2 {
  position: absolute;
  width: 80px;
  bottom: 5%;
  left: 20%;
}
.bg-coffee-3 {
  position: absolute;
  width: 100px;
  bottom: 0%;
  right: 20%;
}

@media screen and (max-width: 1100px) {
  .bg-coffee-1 {
    position: absolute;
    width: 100px;
    top: 10%;
    left: 10%;
  }
}

@media screen and (max-width: 900px) {
  .bg-coffee-1 {
    position: absolute;
    width: 100px;
    top: 10%;
    left: 0%;
  }
}

@media screen and (max-width: 800px) {
  .bg-coffee-1 {
    position: absolute;
    width: 100px;
    top: 10%;
    left: 0%;
    display: none;
  }
}
.blink-login {
  animation: blink-animation 2s linear infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 10%;
  }
  100% {
    opacity: 100%;
  }
}

.card-register {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
}
