.nav-link.active {
  background-color: #ff535d !important;
  opacity: 100%;
  color: white !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-link {
  color: var(--text-color) !important;
  width: 100%;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 12px 8px !important;
  opacity: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav-item {
  display: flex;
  width: calc(100% / 3 - 5px);
}

.nav-pills {
  margin: auto;
  background-color: var(--bg-s-color);
  width: 100%;
  max-width: 600px;
  padding: 10px !important;
  border-radius: 10px;
  justify-content: space-between;
}

.card-reseller {
  background-color: var(--bg-s-color);
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  caret-color: transparent;
}
.tdw {
  width: calc(100% / 3);
}
.tdw1 {
  width: calc(100% / 3);
}

@media screen and (max-width: 768px) {
  .tdw {
    width: 40%;
  }
  .tdw1 {
    width: 20%;
  }
}

.text-r {
  font-weight: 500 !important;
  margin-bottom: 20px;
  caret-color: transparent;
}

.form-reseller {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border-radius: 10px;
  caret-color: transparent;
}
.input-reseller {
  height: 100%;
  width: 100%;
  background-color: var(--bg-s-color);
  border: none;
  outline: none;
  border-radius: 8px;
  caret-color: white;
  padding: 10px 14px;
  color: var(--text-color);
}

.btn-order {
  background-color: #ff535d !important;
  border-radius: 12px !important;
  color: white !important;
  width: 100%;
  max-width: 300px;
}
