.highlight-cal {
  background-color: #ff535d !important;
  border-radius: 5px;
}

.react-calendar {
  background-color: var(--bg-s-color) !important;
  border: none !important;
  border-radius: 5px;
  margin: auto;
}

.react-calendar__navigation__label__labelText {
  color: var(--text) !important;
}

.react-calendar__navigation__arrow {
  color: var(--text);
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: var(--text);
  opacity: 50%;
}

.react-calendar__month-view__days__day {
  color: var(--text);
}

.react-calendar__tile--now {
  background-color: var(--bg-color) !important;
  border-radius: 5px;
}

.react-calendar__navigation {
  margin-bottom: 10px !important;
}

.react-calendar__month-view__weekdays abbr {
  color: var(--text);
  text-decoration: none !important;
  cursor: pointer !important;
}

.react-calendar__tile--active {
  border-radius: 5px;
  background-color: #ff535b37 !important;
}

.overlay-cal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: black;
  cursor: pointer !important;
}

.event-input-container {
  background-color: var(--bg-s-color);
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  overflow-wrap: break-word;
}

.btn-del-cal {
  background-color: red;
  color: white;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 10px;
  height: 16px;
}
.sc-cal {
  background-color: var(--bg-color);
  border-radius: 10px;
  color: var(--text);
}
.title-cal {
  color: var(--text);
}

.apexcharts-menu {
  background-color: var(--bg-color) !important;
  color: var(--text) !important;
  border: none !important;
  border-radius: 5px;
}

.apexcharts-menu :hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}
.apexcharts-text {
  fill: var(--text) !important;
  opacity: 60% !important;
}
.apexcharts-legend {
  height: 40px !important;
  align-items: end !important;
}
.apexcharts-legend-text {
  color: var(--text) !important;
}

.apexcharts-yaxis {
  position: absolute !important;
  transform: translate(16px);
}

.apexcharts-grid-borders line {
  stroke: var(--text) !important;
}
.apexcharts-canvas {
  width: 100% !important;
}

@media screen and (max-width: 1100px) {
  .text-d-head-1 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 992px) {
  .text-d-head-1 {
    font-size: 14px !important;
  }
}

.flex-wrap-rows {
  flex-direction: row;
}

.card-sch {
  margin-right: 24px;
}

@media (max-width: 1030px) {
  .flex-wrap-rows {
    flex-direction: column;
  }
}

.progress-ds {
  position: relative;
  width: 100%;
  height: 24px;
  background-color: var(--bar-prog);
  border-radius: 20px;
  margin-bottom: 10px;
  box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.3),
    inset 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.progress-bar {
  height: 100%;
  border-radius: 20px;
  text-align: center;
  color: white;
  transition: width 0.3s ease-in-out;
  background: linear-gradient(to right, #ff47ed, #ff535d);
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.4),
    inset -2px -2px 4px rgba(0, 0, 0, 0.3), 0px 0px 8px #ff535bb8;
}

.progress-bar2 {
  height: 100%;
  border-radius: 20px;
  text-align: center;
  color: white;
  transition: width 0.3s ease-in-out;
  background: linear-gradient(to right, #2ebadb, #4adbb0);
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.4),
    inset -2px -2px 4px rgba(0, 0, 0, 0.3), 0px 0px 8px #2ebadb;
}

.progress-text {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  width: 100%;
  color: var(--text) !important;
  font-size: 14px;
  font-weight: 500;
}

.dropdown-toggle {
  background-color: #ff535d !important;
  border: none !important;
  font-size: 12px !important;
  padding: 8px 10px !important;
}

.dropdown-item {
  font-size: 12px;
  padding: 2px 5px !important;
  color: white !important;
}

.dropdown-menu {
  padding: 5px !important;
  background-color: #ff535d !important;
  margin-top: 8px !important;
  cursor: pointer;
}

.dropdown-menu :active {
  background-color: var(--bg-s-color) !important;
}

.dropdown-menu :hover {
  background-color: transparent !important;
  /* padding: 2px 5px !important; */
  border-radius: 5px;
}
