/* App.css */
@import url("https://fonts.googleapis.com/css2?family=David+Libre:wght@400;700&display=swap");

/* Terapkan font ke elemen tertentu */
.text-card-s1 {
  font-family: "David Libre", serif;
}

.section-lp {
  background: linear-gradient(to bottom left, #ffd0ae, white);
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  caret-color: transparent;
}

.bgg-s1 {
  position: absolute;
  width: 100%;
  max-width: 70px;
  left: 10%;
  top: 15%;
  z-index: 4;
}

.bgg-s2 {
  position: absolute;
  width: 100%;
  max-width: 50px;
  right: 5%;
  top: 40%;
}

.s1 {
  position: relative;
  min-height: 700px;
}

.img-s1 {
  position: absolute;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  left: 60%;
  top: 40px;
  z-index: 1;
  animation: slideUpDown 4s infinite ease-in-out; /* Menggunakan fungsi timing ease-in-out */
}

@keyframes slideUpDown {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-50%, 100px);
  }
  50% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-50%, 80px);
  }
  100% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-50%, 100px);
  }
}

.biji1-s1 {
  position: absolute;
  width: 100%;
  max-width: 100px;
  left: 50%;
  bottom: 20px;
  z-index: 4;
  animation: slideUpDown1 5s infinite ease-in-out; /* Menggunakan fungsi timing ease-in-out */
}
@keyframes slideUpDown1 {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-300px, -80px);
  }
  50% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-295px, -90px);
  }
  100% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-300px, -80px);
  }
}

.biji2-s1 {
  position: absolute;
  width: 100%;
  max-width: 60px;
  left: 70%;
  bottom: 10px;
  animation: slideUpDown2 8s infinite ease-in-out; /* Menggunakan fungsi timing ease-in-out */
}
@keyframes slideUpDown2 {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-300px, -100%) rotateZ(-90deg);
  }
  50% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-295px, -80%) rotateZ(-90deg);
  }
  100% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(-300px, -100%) rotateZ(-90deg);
  }
}

.biji3-s1 {
  position: absolute;
  width: 100%;
  max-width: 40px;
  left: 20%;
  top: 200px;
  animation: slideUpDown3 6s infinite ease-in-out; /* Menggunakan fungsi timing ease-in-out */
}
@keyframes slideUpDown3 {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(200px, -100px) rotateZ(-90deg);
  }
  50% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(200px, -80px) rotateZ(-90deg);
  }
  100% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(200px, -100px) rotateZ(-90deg);
  }
}

.biji4-s1 {
  position: absolute;
  width: 100%;
  max-width: 70px;
  right: 50%;
  bottom: 0px;
  animation: slideUpDown4 7s infinite ease-in-out; /* Menggunakan fungsi timing ease-in-out */
  z-index: 4;
}
@keyframes slideUpDown4 {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(350px, -100px);
  }
  50% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(350px, -80px);
  }
  100% {
    animation-timing-function: ease-in-out;
    -webkit-transform: translate(350px, -100px);
  }
}

.text-fill-s1 {
  position: absolute;
  width: 800px;
  left: 50%;
  top: 400px;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transform-origin: 50% 50%;
  overflow: hidden; /* Mengatur overflow ke hidden */
  z-index: 0;
}

.text-border-s1 {
  position: absolute;
  width: 800px;
  left: 50%;
  top: 400px;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transform-origin: 50% 50%;
  z-index: 2;
}

.text-coffee-s1 {
  position: absolute;
  top: 240px;
  left: 55%;
  -webkit-transform: translate(-300px, -50%);
  width: 200px;
}
@media screen and (max-width: 992px) {
  .text-fill-s1 {
    position: absolute;
    width: 600px;
    left: 50%;
    top: 400px;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform-origin: 50% 50%;
    overflow: hidden; /* Mengatur overflow ke hidden */
    z-index: 0;
  }

  .text-border-s1 {
    position: absolute;
    width: 600px;
    left: 50%;
    top: 400px;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform-origin: 50% 50%;
    z-index: 2;
  }
}

@media screen and (max-width: 750px) {
  .img-s1 {
    top: 20px;
  }
  .biji2-s1 {
    left: 90%;
    bottom: -20px;
  }
  .biji4-s1 {
    right: 50%;
    bottom: 20px;
  }

  .text2-s1 {
    -webkit-transform: translate(250px, -50%) !important;
  }
  .btn-home-s1 {
    -webkit-transform: translate(-250px, -50%) !important;
  }
  .text-fill-s1 {
    position: absolute;
    width: 550px;
    left: 50%;
    top: 400px;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform-origin: 50% 50%;
    overflow: hidden; /* Mengatur overflow ke hidden */
    z-index: 0;
  }

  .text-border-s1 {
    position: absolute;
    width: 550px;
    left: 50%;
    top: 400px;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform-origin: 50% 50%;
    z-index: 2;
  }
}

@media screen and (max-width: 600px) {
  .text-fill-s1 {
    width: 450px;
  }
  .biji4-s1 {
    right: 60%;
    bottom: 20px;
  }

  .text-border-s1 {
    width: 450px;
  }

  .text2-s1 {
    font-size: 90px !important;
    -webkit-transform: translate(200px, -50%) !important;
  }
  .btn-home-s1 {
    -webkit-transform: translate(-200px, -50%) !important;
  }

  @keyframes slideUpDown1 {
    0% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-250px, -40%);
    }
    50% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-245px, -50%);
    }
    100% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-250px, -40%);
    }
  }

  @keyframes slideUpDown2 {
    0% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-180px, -100px) rotateZ(-90deg);
    }
    50% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-185px, -80px) rotateZ(-100deg);
    }
    100% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-180px, -100px) rotateZ(-90deg);
    }
  }

  @keyframes slideUpDown4 {
    0% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(250px, -100px) rotateZ(-90deg);
    }
    50% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(250px, -80px) rotateZ(-90deg);
    }
    100% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(250px, -100px) rotateZ(-90deg);
    }
  }
  .text-border-s1 {
    -webkit-text-stroke-width: 2px;
  }
}

@media screen and (max-width: 450px) {
  .text-fill-s1 {
    width: 370px;
  }
  .biji4-s1 {
    right: 70%;
    bottom: 20px;
  }

  .text-border-s1 {
    width: 370px;
  }

  .text2-s1 {
    font-size: 80px !important;
    -webkit-transform: translate(180px, -50px) !important;
  }
  .btn-home-s1 {
    -webkit-transform: translate(-170px, -0px) !important;
  }

  @keyframes slideUpDown1 {
    0% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-200px, -100px);
    }
    50% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-205px, -80px);
    }
    100% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(-200px, -100px);
    }
  }

  @keyframes slideUpDown3 {
    0% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(100px, -100px);
    }
    50% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(100px, -80px);
    }
    100% {
      animation-timing-function: ease-in-out;
      -webkit-transform: translate(100px, -100px);
    }
  }
}

@media screen and (max-width: 400px) {
  .text-fill-s1 {
    width: 350px;
  }

  .text-border-s1 {
    width: 350px;
  }
}

.text-home {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
}

.text2-s1 {
  position: absolute;
  top: 80%;
  color: transparent;
  font-size: 100px;
  right: 50%;
  -webkit-transform: translate(300px, -50%) rotateZ(5deg);
  text-shadow: 0px 0px 15px #000000d2;
  overflow: hidden;
  padding: 15px;
}

.text2-s1 span {
  -webkit-text-fill-color: #ffc8a05e;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffe4af;
  -webkit-background-clip: text; /* Mengatur background clip ke text */
  color: transparent; /* Membuat teks menjadi transparan */
  display: inline-block; /* Mengatur elemen teks menjadi inline block */
  font-weight: 600;
}

.btn-home-s1 {
  position: absolute;
  top: 82%;
  background-color: #ff535d !important;
  left: 50%;
  -webkit-transform: translate(-300px, -50%);
  border-radius: 20px !important;
  border: none !important;
  box-shadow: 0px 0px 10px #ff535d;
  display: flex;
  align-items: center;
  height: 40px;
  z-index: 2;
}

.card-s1 {
  perspective: 1000px;
  width: 100%;
  display: flex;
  height: 160px;
  cursor: pointer;
}

.card-s1-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-s1:hover .card-s1-inner {
  transform: rotateY(180deg);
}

.card-s1-front,
.card-s1-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  border-top-left-radius: 40px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 40px;
  background-color: #4a2721;
}

.card-s1-back {
  color: black;
  transform: rotateY(180deg);
}

.img-card-s1 {
  width: 160px;
  height: auto;
  object-fit: cover;
}

.tc2 {
  font-size: 12px;
  color: white;
}

.text-card-s1 {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-size: 30px;
  color: transparent; /* Membuat teks menjadi transparan */
  background: linear-gradient(
    to right,
    #ffd0ae,
    white,
    #ffd0ae
  ); /* Gradien linear */
  -webkit-background-clip: text; /* Set background clip ke text */
  background-clip: text; /* Set background clip ke text (standar) */
  font-weight: 700;
}

.text-card-s1-b {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-size: 16px;
  color: transparent; /* Membuat teks menjadi transparan */
  background: linear-gradient(
    to right,
    #ffd0ae,
    white,
    #ffd0ae
  ); /* Gradien linear */
  -webkit-background-clip: text; /* Set background clip ke text */
  background-clip: text; /* Set background clip ke text (standar) */
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .img-card-s1 {
    width: 100px;
    height: 120px;
  }
  .text-card-s1 {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 992px) {
  .img-card-s1 {
    width: 80px;
    height: 100px;
  }
  .text-card-s1 {
    font-size: 20px !important;
  }
  .card-s1 {
    height: 140px;
  }
  .tc2 {
    font-size: 9px;
  }
}
@media screen and (max-width: 769px) {
  .text-card-s1 {
    font-size: 30px !important;
  }
  .text-card-s1-b {
    font-size: 20px !important;
  }

  .tc2 {
    font-size: 12px;
  }

  .text-coffee-s1 {
    top: 180px;
    left: 40%;
    width: 150px;

    -webkit-transform: translate(-150px, -50%);
  }
}

@media screen and (max-width: 475px) {
  .text-card-s1 {
    font-size: 20px !important;
  }

  .text-card-s1-b {
    font-size: 18px !important;
  }
  .text-coffee-s1 {
    top: 230px;
    left: 42%;
    width: 150px;

    -webkit-transform: translate(-150px, -50%);
  }
  .img-s1 {
    top: 80px;
  }
}

.t-s2 {
  width: 500px;
  position: relative;
  bottom: -30px;
}

@media screen and (max-width: 600px) {
  .t-s2 {
    width: 350px;
    bottom: -22px;
  }
}

@media screen and (max-width: 400px) {
  .t-s2 {
    width: 300px;
    bottom: -20px;
  }
}

.bg-s2 {
  background-color: rgba(0, 0, 0, 0.3);
}

.img-s2 {
  width: 100%;
  max-width: 150px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.img-s2:hover {
  transform: scale(1.1); /* Scale up by 10% */
}

.t-s3 {
  width: 400px;
  position: relative;
  bottom: -40px;
  z-index: 2;
  right: 30px;
  transform: translateX(50%);
}

.t-s4 {
  width: 350px;
}

.img-s4 {
  max-width: 400px;
}

@media screen and (max-width: 992px) {
  .t-s3 {
    width: 300px;
    bottom: -30px;
  }
}

@media screen and (max-width: 650px) {
  .t-s3 {
    width: 250px;
    bottom: -22px;
  }
}
@media screen and (max-width: 530px) {
  .t-s3 {
    width: 220px;
    bottom: -20px;
  }
  .t-s4 {
    width: 300px;
  }
  .img-s4 {
    max-width: 250px;
  }
}

@media screen and (max-width: 400px) {
  .t-s3 {
    width: 200px;
    bottom: -20px;
  }
  .t-s4 {
    width: 250px;
  }
}

.cardSliderProdh3 {
  width: 1000px;
  height: 300px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  .cardSliderProdh3 {
    width: 800px;
    height: 240px;
    margin: auto;
  }
}

@media screen and (max-width: 992px) {
  .cardSliderProdh3 {
    width: 650px;
    height: 200px;
    margin: auto;
  }
}

@media screen and (max-width: 769px) {
  .cardSliderProdh3 {
    width: 500px;
    height: 150px;
    margin: auto;
  }
}

@media screen and (max-width: 530px) {
  .cardSliderProdh3 {
    width: 400px;
    height: 120px;
    margin: auto;
  }
}
@media screen and (max-width: 430px) {
  .cardSliderProdh3 {
    width: 360px;
    height: 110px;
    margin: auto;
  }
}
@media screen and (max-width: 400px) {
  .cardSliderProdh3 {
    height: 102px;
    margin: auto;
  }
}

.text-card-s4 {
  font-optical-sizing: auto;
  font-size: 20px;
  color: transparent; /* Membuat teks menjadi transparan */
  background: linear-gradient(to right, #1e1e1e, #991411); /* Gradien linear */
  -webkit-background-clip: text; /* Set background clip ke text */
  background-clip: text; /* Set background clip ke text (standar) */
  font-weight: 700;
}
.hr-tcs4 {
  border-image: linear-gradient(to right, #1e1e1e, #991411);
  border-image-slice: 1;
  opacity: 100%;
  display: inline-block;
  width: 100%;
  border-top: 2px solid;
  margin-top: 5px;
  margin-bottom: 5px;
}

.t-h4-1 {
  margin-bottom: 30px;
  max-width: 300px;
}
.t-h4-2 {
  margin-bottom: 30px;
  max-width: 400px;
  margin-left: 30px;
}
.t-h4-3 {
  margin-bottom: 30px;
  max-width: 500px;
  margin-left: 60px;
}
.t-h4-4 {
  margin-bottom: 30px;
  max-width: 450px;
  margin-left: 60px;
}
.t-h4-5 {
  margin-bottom: 30px;
  max-width: 350px;
  margin-left: 30px;
}
.t-h4-6 {
  margin-bottom: 30px;
  max-width: 300px;
}

@media screen and (max-width: 769px) {
  .t-h4-1 {
    max-width: 250px;
    margin-bottom: 20px;
  }
  .t-h4-2 {
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 300px;
  }
  .t-h4-3 {
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 400px;
  }
  .t-h4-4 {
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 350px;
  }
  .t-h4-5 {
    max-width: 300px;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .t-h4-6 {
    max-width: 250px;
  }
  .text-card-s4 {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1200px) {
  .text-card-s4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .text-card-s4 {
    font-size: 12px;
  }
}

.card-s5 {
  background: radial-gradient(
    circle at 80% 20%,
    #360003ec,
    rgba(0, 0, 0, 0.8)
  ); /* Radial gradient */
  width: 100%;
  height: 200px;
  border-radius: 10px;
  max-width: 350px;
  margin: auto;
  position: relative;
}
.card-s5-2 {
  background: radial-gradient(
    circle at 20% 80%,
    #360003ec,
    rgba(0, 0, 0, 0.8)
  ); /* Radial gradient */
  width: 100%;
  height: 200px;
  border-radius: 10px;
  max-width: 350px;
  margin: auto;
  position: relative;
}

.tit-s5 {
  font-size: 60px;
  font-weight: 700;
  background: linear-gradient(
    to bottom,
    #ff535d,
    #ffffff
  ); /* Gradien untuk teks */
  -webkit-background-clip: text; /* Dukungan untuk browser berbasis WebKit */
  background-clip: text; /* Standar background-clip */
  color: transparent; /* Membuat teks menjadi transparan untuk menampilkan gradien */
}

.stit-s5 {
  font-size: 18px;
  font-weight: 600;
}

.img-card-s5-1 {
  width: 250px;
  position: absolute;
  bottom: -50px;
  left: -130px;
  transition: transform 0.3s ease; /* Menambahkan transisi untuk animasi */
}

.img-card-s5-1:hover {
  transform: rotate(-10deg); /* Rotasi sebesar 40 derajat saat di-hover */
}

.img-card-s5-2 {
  width: 250px;
  position: absolute;
  top: -100px;
  right: -110px;
  transition: transform 0.3s ease; /* Menambahkan transisi untuk animasi */
}

.img-card-s5-2:hover {
  transform: rotate(10deg); /* Rotasi sebesar 40 derajat saat di-hover */
}

@media screen and (max-width: 500px) {
  .img-card-s5-1 {
    width: 200px;
    position: absolute;
    bottom: -40px;
    left: -50px;
    transition: transform 0.3s ease; /* Menambahkan transisi untuk animasi */
  }
  .img-card-s5-2 {
    width: 200px;
    position: absolute;
    top: -80px;
    right: -40px;
    transition: transform 0.3s ease; /* Menambahkan transisi untuk animasi */
  }
}

.animated-s6 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s6.visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-s4 {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s4.visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-s5 {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s5.visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-s5b {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s5b.visible {
  opacity: 1;
  transform: translateY(0);
}
