.camHidden {
  display: none;
}
.qr-reader video {
  width: 100%;
  max-width: 500px;
  height: 400px;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.qr-reader {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  margin: 0 auto;
  position: relative;
}

.btn-scan {
  max-width: 300px;
}

.pincode-input-text {
  padding: 0 !important;
  margin: 0 3px !important;
  text-align: center;
  border: 1px solid;
  background: transparent;
  width: 35px !important;
  height: 35px !important;
  border-radius: 5px;
}
.pincode-input-text:focus {
  outline: none;
  box-shadow: none;
}

.cardkodeverif {
  z-index: 3;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  flex-direction: column;
  background-color: #fff5ee;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.272);
  width: 100%;
  max-width: 320px;
}

.bg-cardkodeverif {
  width: 100%;
  height: 100%;
  background-color: #ffeadc;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 2;
}
