.footer-ddn {
  background: linear-gradient(
      to bottom,
      rgba(82, 48, 22, 0.4),
      rgba(27, 21, 17, 0.4)
    ),
    url("./bg.png"); /* Ganti dengan path ke gambar Anda */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #47240cd9;
}

.title-footer {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.text-footer {
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .title-footer {
    font-size: 16px;
  }
  .text-footer {
    font-size: 12px;
  }
}

.img-f {
  transform: rotateZ(15deg);
}
