.modalp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
  max-width: 500px;
}
.modal-blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.183);
  z-index: 1;
}

.modal-content-product {
  background: linear-gradient(to bottom left, #ffd0ae, white);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.5);
  margin: 20px;
}

.modal-content-product-text {
  color: black;
}

.input-product {
  height: 40px;
  min-width: 100px;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 5px 10px;
  background-color: #eac9b2 !important;
  color: black;
  caret-color: auto;
}

.card-p-s1 {
  background-color: #ad724a63;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.img-card-p1 {
  width: 100%;
  max-width: 450px;
  border-radius: 20px;
}

.bg-p {
  background-color: #ffffff41;
  padding: 10px 20px;
}

.text-prod {
  font-size: 50px;
  color: #17181a;
  line-height: 55px;
}

.btn-buy {
  background-color: #ff535d !important;
  box-shadow: 0px 0px 10px #ff535bd1;
  border: none !important;
  outline: none !important;
}

.text-price {
  font-size: 34px;
  font-weight: 400;
}

.text-prices {
  font-size: 45px;
  font-weight: 400;
  color: #ff535d;
  font-weight: 700;
}

.text-des {
  font-size: 20px;
  max-width: 500px;
}

.bgp {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
}

.cardSliderProd {
  margin: auto;
  width: 100%;
  max-width: 1000px;
  height: 300px;
  background-color: #17181a;
  border-radius: 20px;
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: relative;
}

.slideHead {
  font-weight: 700;
  font-family: "Dancing Script";
  position: absolute;
  top: -50px;
  left: 15%;
  font-size: 40px;
  background: linear-gradient(135deg, #dc322f, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke: 0.28px white;
  text-stroke: 1px white;
  z-index: 2;
  text-shadow: 2px -3px 12px rgba(0, 0, 0, 0.5);
}

.slideTitle {
  max-width: 60%;
  font-size: 40px;
  color: #ff535d;
  font-weight: 600;
}
.slideSubtitle {
  max-width: 50%;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1400px) {
  .slideHead {
    left: 10%;
  }
}

@media screen and (max-width: 1200px) {
  .slideHead {
    left: 6%;
  }
}

@media screen and (max-width: 992px) {
  .text-price {
    font-size: 36px;
    text-align: center;
  }
  .text-prod {
    font-size: 50px;
    text-align: center;
    line-height: normal;
  }
  .text-des {
    text-align: center;
    margin: auto;
    font-size: 18px;
  }
  .btn-buy {
    margin: auto;
  }
  .text-prices {
    font-size: 45px;
    text-align: center;
    margin-top: 16px;
  }
  .cardSliderProd {
    background-size: fill;
    background-repeat: no-repeat;
    background-position: left;
    height: 200px;
    border-radius: 15px;
    padding: 15px;
  }
  .slideTitle {
    font-size: 30px;
  }
  .slideSubtitle {
    font-size: 14px;
    max-width: 55%;
  }
  .slideHead {
    font-size: 36px;
    top: -48px;
  }
}
@media screen and (max-width: 767px) {
  .slideTitle {
    font-size: 24px;
  }
  .slideSubtitle {
    font-size: 13px;
    max-width: 60%;
  }
}

@media screen and (max-width: 540px) {
  .slideTitle {
    font-size: 22px;
  }
  .slideSubtitle {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .text-prod {
    font-size: 34px;
  }
  .text-prices {
    font-size: 36px;
  }

  .text-price {
    font-size: 26px;
  }
  .text-des {
    font-size: 14px;
    text-align: justify;
  }
  .bgp {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
  }
  .cardSliderProd {
    background-size: fill;
    background-repeat: no-repeat;
    background-position: left;
    height: 140px;
    border-radius: 10px;
    padding: 10px;
  }
  .slideTitle {
    font-size: 16px;
    max-width: 100%;
  }
  .slideSubtitle {
    font-size: 9px;
    max-width: 55%;
  }
}

.animated-s1 {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.animated-s1.visible {
  opacity: 1;
}

.animated-s1-title {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s1-title.visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-s1-subtitle {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s1-subtitle.visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-s1-text {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-s1-text.visible {
  opacity: 1;
  transform: translateY(0);
}
