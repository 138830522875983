.card-img-scan {
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 500px;
  margin: auto;
  border-radius: 10px;
}

.outer-scan {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  position: relative;
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.scan-result {
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
}

.pin-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: auto;
  padding: 16px;
  background-color: var(--btn-bg);
  border-radius: 10px;
  flex-direction: column;
}
.pin-input {
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  height: 38px !important;
  width: 38px !important;
  caret-color: black !important;
  margin: 0px 3px;
}

.inv {
  max-width: 900px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.wqrt {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cardWqr {
  display: flex;
  justify-content: end;
}

.cardWqr1 {
  display: flex;
  justify-content: center;
}

.textinv {
  width: 140px;
}

@media screen and (max-width: 576px) {
  .textinv {
    width: 100px;
  }
}
