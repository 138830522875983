.index-module_num__j6XH3 {
  width: 80px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-weight: 600;
  text-shadow: 0px 0px 10px black;
}
@media screen and (max-width: 992px) {
  .index-module_num__j6XH3 {
    width: 70px;
    height: 100px;
    font-size: 60px;
  }
}

@media screen and (max-width: 767px) {
  .index-module_num__j6XH3 {
    width: 65px;
    height: 100px;
    font-size: 50px;
  }
  .index-module_slot__DpPgW {
    margin: 5px !important;
    border: 2px solid rgba(0, 0, 0, 0.817) !important;
  }
}

@media screen and (max-width: 532px) {
  .index-module_num__j6XH3 {
    width: 55px;
    height: 80px;
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .index-module_num__j6XH3 {
    width: 45px;
    height: 75px;
    font-size: 40px;
  }
}

@media screen and (max-width: 410px) {
  .index-module_num__j6XH3 {
    width: 40px;
    height: 70px;
    font-size: 36px;
  }
}

@media screen and (max-width: 400px) {
  .index-module_num__j6XH3 {
    width: 40px;
    height: 60px;
    font-size: 34px;
  }
}
@media screen and (max-width: 350px) {
  .index-module_num__j6XH3 {
    width: 35px;
    height: 55px;
    font-size: 32px;
  }
}
.index-module_slot__DpPgW {
  margin: 8px;
  box-shadow: inset 4px 8px 10px rgba(0, 0, 0, 0.4),
    inset -4px -8px 10px rgba(0, 0, 0, 0.4),
    0px 0px 8px rgba(255, 255, 255, 0.2);
  background-color: rgb(165, 164, 164);
  border: 3px solid rgba(0, 0, 0, 0.78);
  border-radius: 10px;
}

.spinner {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-color);
  transform: translate(0%, 0%);
  display: flex;
  justify-content: start;
  flex-direction: column;
  caret-color: transparent;
}

.card-spin {
  background-color: var(--bg-s-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  max-width: 720px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.146);
}

.spin-reward {
  background-color: var(--bg-s-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
}

.title-spin {
  opacity: 90%;
}

.cdspin {
  background-color: var(--bg-color);
  height: 40px;
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 14px;
  opacity: 80%;
}

.config-spin-r {
  position: absolute;
  top: -8px;
  left: 40px;
  background-color: var(--bg-color);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}
