.nav-ddn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-outer {
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 10;
  background-color: #ffe6d574;
}

.nav-text {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  cursor: pointer;
}
.nav-text-active {
  color: black;
  font-weight: 600;
  cursor: pointer;
}

.btn-nav {
  background-color: #ff535d !important;
  border: none !important;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ff535d;
  font-size: 14px;
}

.btn-nav-m {
  background-color: #ff535d !important;
  border: none !important;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ff535d;
  font-size: 14px;
  display: none !important;
}

.btn-nav-m2 {
  background-color: transparent;
  border: none !important;
  font-size: 14px;
  display: none !important;
}

.nav-m-o {
  display: none;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.1);
  -webkit-transition: ease-in-out 3s;
}
.nav-m-o-active {
  padding: 20px;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.1);
}

.nav-m-inner {
  color: rgb(28, 28, 28);
  text-shadow: 0px 0px 10px white;
  font-size: 20px;
  padding: 10px 0px;
  border-radius: 20px;
  font-weight: 700;
  animation: fadeIn 1s forwards; /* Animasi saat elemen dimuat */
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
.nav-m-inner1 {
  color: rgb(28, 28, 28);
  text-shadow: 0px 0px 10px white;
  font-size: 20px;
  padding: 10px 0px;
  border-radius: 20px;
  font-weight: 700;
  animation: fadeIn1 1s 0.25s forwards; /* Animasi saat elemen dimuat */
  opacity: 0;
}

@keyframes fadeIn1 {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
.nav-m-inner2 {
  color: rgb(28, 28, 28);
  text-shadow: 0px 0px 10px white;
  font-size: 20px;
  padding: 10px 0px;
  border-radius: 20px;
  font-weight: 700;
  animation: fadeIn2 1s 0.5s forwards; /* Animasi saat elemen dimuat */
  opacity: 0;
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
.nav-m-inner3 {
  animation: fadeIn3 1s 0.75s forwards; /* Animasi saat elemen dimuat */
  opacity: 0;
}

@keyframes fadeIn3 {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@media screen and (max-width: 768px) {
  .btn-nav-m {
    background-color: #ff535d !important;
    border: none !important;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #ff535d;
    font-size: 14px;
    display: flex !important;
  }
  .btn-nav-m2 {
    background-color: transparent;
    border: none !important;
    border-radius: 20px;
    font-size: 14px;
    display: flex !important;
  }

  .nav-w {
    display: none;
  }
}
