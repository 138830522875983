.modalh {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-product-h {
  background: var(--bg-s-color);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.5);
  margin: 20px;
  border: 1px solid;
  border-color: var(--text-color);
  position: relative;
  overflow: scroll;
  max-height: 90vh;
}

.modal-content-product-text-h {
  color: var(--text-color);
}

.input-product-h {
  height: 40px;
  min-width: 100px;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 5px 10px;
  background-color: #eac9b2 !important;
  color: var(--text-color);
  caret-color: auto;
}

.dropdownAH button {
  background-color: var(--bg-color) !important;
  font-size: 14px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.di-s {
  background-color: #5353534e !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}

.ddms {
  max-height: 300px;
  overflow: scroll;
}

.btn-submit {
  background-color: #17a1fa !important;
  border-radius: 10px !important;
}

.dm-s {
  overflow: scroll;
  max-height: 300px;
  border-radius: 0 !important;
}
