.modal-alert {
  background: linear-gradient(to bottom left, #ffd0ae, rgb(255, 255, 255));
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 0px 10px rgba(159, 158, 158, 0.2);
  max-width: 400px;
}

.imgalert {
  width: 100%;
  max-width: 300px;
}
.btn-s {
  background-color: #2dcc70 !important;
  color: white !important;
  border-radius: 15px !important;
  width: 100%;
  max-width: 200px;
}

.btn-f {
  background-color: #ff535d !important;
  color: white !important;
  border-radius: 15px !important;
  width: 100%;
  max-width: 200px;
}
.text-s {
  color: rgb(48, 48, 48);
}

.text-f {
  color: #ff535d;
}

.modala {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
