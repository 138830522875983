.canvasLoadingP {
  caret-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Atur transisi untuk mengubah opacity selama 0.5 detik */
}

.canvasLoadingP.hidden {
  opacity: 0;
  pointer-events: none; /* Tambahkan ini untuk menonaktifkan interaksi pengguna dengan elemen yang disembunyikan */
  transition: opacity 0.5s ease-in-out; /* Tetapkan transisi bahkan ketika elemen disembunyikan */
}
