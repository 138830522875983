.modalProd {
  max-width: 500px;
}

.mPrevQr {
  background-color: white;
  display: flex;
  align-items: center;
}

.textvcode {
  color: #0b3ffb;
  font-weight: 700;
  letter-spacing: 3px;
}

.textvcode2 {
  color: #0b3ffb;
  font-weight: 700;
  letter-spacing: 1px;
}

.ReactModal__Overlay {
  position: relative;
  overflow: scroll;
}

.slopQr {
  border: 1px solid transparent;
  border-radius: 15px;
}
.slopQrText {
  width: 260px;
  height: 260px;
  margin-left: 20px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

.sQrinn {
  /* Mengatur poros rotasi di tengah */
  transform-origin: center;
  /* Melakukan rotasi sebesar 90 derajat */
  transform: rotate(90deg);
}
