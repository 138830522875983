.addPromo {
  z-index: 12;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-s-color);
  width: 90%;
  max-width: 900px;
  border-radius: 10px;
}
.blocker-addPromo {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  z-index: 12;
  opacity: 90%;
}
