.text-l {
  max-width: 160px;
  text-align: end;
}

.modal-content-product-l {
  background: var(--bg-s-color);
  padding: 20px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.5);
  margin: 20px;
  border: 1px solid;
  border-color: var(--text-color);
  position: relative;
  overflow: scroll;
  width: 100%;
  max-width: 400px;
}

.hidden-content {
  position: absolute;
  left: -99999px;
  top: -99999px;
}
