.card-img-scan {
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 500px;
  margin: auto;
}

.btn-sp {
  background-color: #ff535d !important;
  color: white;
  border-radius: 10px !important;
}

.cardselected {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--bg-color);
}
